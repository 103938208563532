import React from "react"
import { Link, graphql } from "gatsby"
import {
    MdSupervisorAccount,
    MdDvr,
    MdArrowBack,

    MdPhonelink,
    MdCheck,
    MdArrowForward,
  } from 'react-icons/md';


import SEO from "../components/seo"
import Layout from "../components/layout"

const LandingPage = ({data: {image1,laptop }}) => {


    let imgProd = laptop.childImageSharp.fluid.src;

    return (
        <Layout changeHeader={2}>
            <SEO title={`Landing page`} />

            <section className="hero-wrap hero-wrap-2 mb-2" style={{backgroundImage: `url(${image1.childImageSharp.fluid.src})`}} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                    <div className="col-md-1" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                        <Link to ="/#blog-section" className="icon ml-5 btn btn-primary" title="Regresar">
                            <MdArrowBack
                                color="#fff"
                                size="2.3em"
                            /> 
                        </Link>
                        
                    </div>
                    <div className="col-md-11 text-center">
                        <h1 className="mb-2 bread" data-sal="slide-left" data-sal-duration="2000" data-sal-easing="ease">Landing page </h1>
                        <p className="breadcrumbs"><span className="mr-2">
                            
                                <Link  className="" to="/#blog-section">Productos</Link> > </span> <span>Landing Page</span>
                            </p>
                    </div>
                    </div>
                </div>
            </section>
            <section className="text-justify">
            
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="sidebar-box" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                <h3 className="text-center">Landing Page</h3>

                                <p>&nbsp;&nbsp;&nbsp;&nbsp;En la mercadotecnia en internet, se denomina página de aterrizaje (del inglés landing pages, en España también se utiliza el término "página de destino") a una página web a la que una persona llega tras pulsar en el enlace o botón en una guía, un portal o algún banner o anuncio de texto situado en otra página web, aplicación, red social, E-mail o portal de internet. En la mayoría de los casos esta página web es una extensión del anuncio de promoción, donde se explica más detalladamente la oferta del producto o servicio que se está promocionando a través de una carta de ventas.</p>

                                <h3 className="text-center">¿Por qué es importante?</h3>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Su razonamiento es sencillo: si ofrecemos algo que seduzca al usuario, éste estará más dispuesto a dejar información a través de un formulario, si con ello va a poder acceder al mismo y a otros contenidos de interés.
                               </p>

                                <p>&nbsp;&nbsp;&nbsp;&nbsp; Esta página nos ayuda a convertir a los usuarios en clientes finales, de ahí la importancia de conseguir crear una landing page que convierta de verdad. </p>                                           
                            </div>
                        </div>

                        <div className="col-md-6 col-12 p-md-7 mt-5" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >
                            <img src={imgProd} alt="img project" className="img-fluid rounded mb-1" />
                        </div>

                        <div className="col-md-12 col-12 p-md-4 mt-3" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >

                            <section className="ftco-section contact-section ftco-no-pb" id="contact-section">
                              <div className="container-fluid">
                                <div className="row justify-content-center mb-1 pb-3">
                                  <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                                    <h3 className="mb-4">Pasos del desarrollo de una Landing Page</h3>
                                  </div>
                                </div>
                                <div className="row d-flex contact-info mb-0 justify-content-center">
                                  
                                  <div className="col-md-4 col-lg-2 d-flex "> 
                                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px', background: '#388e3c'}} data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
                                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                                        <MdSupervisorAccount color="#ffffff" size="2em"/>
                                      </div>
                                      <h6 className="">Solicitud de Información</h6>
                                    </div>
                                  </div>

                                  <div className="pt-5 d-none d-sm-none d-md-block" data-sal="slide-right" data-sal-duration="500" data-sal-easing="ease">
                                    <MdArrowForward
                                        color="#33a4db"
                                        size="3em"
                                    /> 
                                  </div>

                                  <div className="col-md-4 col-lg-2 d-flex ">
                                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#f9a825'}} data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                                        <MdDvr color="#ffffff" size="2em"/>
                                      </div>
                                      <h6 className="">Diseño y Maquetación</h6>
                              
                                    </div>
                                  </div>

                                  <div className="pt-5 d-none d-sm-none d-md-none d-lg-block" data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease">
                                    <MdArrowForward
                                        color="#33a4db"
                                        size="3em"
                                    /> 
                                  </div> 
                                  
                                  <div className="col-md-4 col-lg-2 d-flex ">
                                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#ef6c00'}} data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                                        <MdPhonelink color="#ffffff" size="2em"/>
                                      </div>
                                      <h6 className="">Presentación de propuesta</h6>
                                    </div>
                                  </div>

                                  <div className="pt-5 d-none d-sm-none d-md-block" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                                    <MdArrowForward
                                        color="#33a4db"
                                        size="3em"
                                    /> 
                                  </div>

                                  <div className="col-md-4 col-lg-2 d-flex ">
                                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#039be5' }} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                                        <MdCheck color="#ffffff" size="2em"/>
                                      </div>
                                      <h6 className="">Entrega de producto</h6>
                                    </div>
                                  </div>

                                </div>
                                
                              </div>
                            </section>


                        </div>

                    </div>
                </div>
            </section>

            <section className="ftco-section contact-section ftco-no-pb" id="contact-section">
              <div className="container">
                <div className="row justify-content-center mb-3 pb-3">
                  <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                    <h4>Requerimientos</h4>
                  </div>
                </div>
                <div className="row d-flex contact-info mb-0 justify-content-center">
                  
                  <div className="col-md-12 col-lg-6 d-flex "> 

                    <div className="container">
                      <div className="row mb-5 pb-3 border-right justify-content-center">
                        <h5 className="mb-3"> Consernientes a la agencia:</h5> 

                        <ul>
                          <li>Requerimientos de servicios.</li>
                          <li>Requerimientos técnicos.</li>
                          <li>Requerimientos de propiedad legal</li>
                          <li>Requerimientos de contenido y su creación.</li>
                          <li>Requerimientos de diseño.</li>
                          <li>Requerimientos de navegación y usabilidad.</li>
                          <li>Requerimientos de SEO/Posicionamiento.</li>
                          <li>Requerimientos del Hosting o Alojamiento WEb.</li>
                          <li>Requerimientos en metodología de trabajo.</li>
                        </ul>
                      </div>
                    </div>


                    
                  </div>

                  <div className="col-md-12 col-lg-6 d-flex "> 
                    <div className="container">
                      <div className="row mb-5 pb-3 justify-content-center">
                        <h5 className="mr-5 mb-4"> Consernientes al cliente:</h5> 

                        <ul>
                          <li>objetivos generales de la empresa, Misión y Visión.</li>
                          <li>Lista de productos y fotos de los mismos.</li>
                          <li>Lista de proyectos y fotos de las obras.</li>
                          <li>Años de experiencia.</li>
                          <li>Slogan si lo tienen.</li>
                          <li>Lista con nombres, apellidos, cargos y profesión del equipo.</li>
                          <li>Cantidad de productos y proyectos realizados.</li>
                          <li>Redes sociales si la tienen. (Cuentas de usuario).</li>
                          <li>Fotos de productos o proyectos en construcción.</li>
                          <li>Estudio y definición del nombre del dominio. (www.ejemplo.com.ve).</li>
                          <li>Teléfonos de contactos.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
                
              </div>
            </section>

           
            
        </Layout>
    )
}

export default LandingPage

export const pageQuery = graphql`
  query FacByProdc {

    image1: file(relativePath: { eq: "banners1.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
            }
        }
    }
   
    laptop: file(relativePath: { eq: "laptop.jpg" }) {
      childImageSharp {
          fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
          }
      }
  }

  }
`